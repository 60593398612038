import LocalizedStrings from 'react-localization';

export const page_names = new LocalizedStrings({
    it: {
        page_products:"/prodotti",
        page_faq:"/faq",
        page_contact_us:"/contattaci",
        page_product_detail:"/dettaglio_prodotto",
        page_create_your_look:"/crea_il_tuo_look",
        page_find_your_color:"/trova_il_tuo_colore"
    },
    /*en: {
        page_products:"/products",
        page_faq:"/faq",
        page_contact_us:"/contact_us",
        page_product_detail:"/product_detail",
        page_create_your_look:"/create_your_look",
        page_find_your_color:"/find_your_color"
    }*/
});

export const stringsHome = new LocalizedStrings({
    it: {
        title: "Una rivoluzione per i tuoi capelli",
        lbl_box_1_title:"Trova",
        lbl_box_1:"Il tuo",
        lbl_box_1_2:"prodotto",
        lbl_box_2_title:"Scopri",
        lbl_box_2:"Tutti i",
        lbl_box_2_2:"prodotti",
        lbl_box_3_title:"Crea",
        lbl_box_3:"Il tuo",
        lbl_box_3_2:"look",
        btn_box_vai:"VAI",
        btn_box_vai_blocked:"IN ARRIVO",
        btn_scopri_di_piu:"SCOPRI DI PIÙ",
        lbl_site_name:"BIOKERATIN",
        lbl_subtitle_landing:"Una rivoluzione per i tuoi capelli",
        btn_contact_us:"Contattaci",

    }
});

export const stringsContainer = new LocalizedStrings({
    it: {
        placeholderTextboxCerca: "Cerca un prodotto",
        lbl_title_product_of_the_month: "Il prodotto del mese",
        lbl_footer:"©2020 Dietalinea by GDP S.r.l. - Corso Perrone Ferdinando Maria 35/R - 16152 Genova"
    }
});

export const stringsStoreLocator = new LocalizedStrings({
    it: {
        btn_store_locator:"STORE LOCATOR",
        lbl_find_shop:"Cerca negozio",
        lbl_posizione:"Posizione",
        lbl_map:"Mappa",
        lbl_ray:"Nel raggio di",
        placeholder_find_shop:"Cerca per nome, città, CAP",
        lbl_less_than:"Meno di ",
        btn_map:"Mappa",
        btn_driving_directions:"Indicazioni Stradali",
        lbl_info_mobile:"*Tutti i prodotti BioKeratin sono disponibili nei negozi"
    }
});

export const stringsSidebar = new LocalizedStrings({
    it: {
        li_company: "Azienda",
        li_products:"Tutti i prodotti",
        li_faq:"Domande frequenti",
        li_contact_us:"Contattaci"
    }
});

export const stringsFAQ = new LocalizedStrings({
    it: {
        lbl_title: "Domande Frequenti",
        lbl_sub_title:"Compila il form se hai qualche richiesta da farci"
    }
});

export const stringsContactUs = new LocalizedStrings({
    it: {
        lbl_title: "Contatti",
        lbl_sub_title:"Compila il form se hai qualche richiesta da farci",
        placeholder_name: "Nome",
        placeholder_surname: "Cognome",
        placeholder_email: "Email",
        placeholder_message: "Messaggio",
        btn_submit: "Invia",
        btn_call_us: "Chiamaci",
        btn_write_us: "Scrivici",
        lbl_mobile:"",
        TEL_CONTACT_US:"+39 010 6420124",
        EMAIL_CONTACT_US:"info@biokeratin.it",
        lbl_mail:"Scrivici",
        lbl_email_error:"Email non corretta"
    }
});

export const stringsProducts = new LocalizedStrings({
    it: {
        lbl_title: "I nostri prodotti",
        btn_filter:"Filtra",
        lbl_title_sidebar:"Filtra",
        placehoolder_find_product:"Cerca un prodotto",
        placehoolder_order_by:"Ordina per",
        btn_submit:"APPLICA FILTRI",
        lbl_count_products:"prodotti",
        option_order_by_az:"A-Z",
        option_order_by_za:"Z-A",
        option_order_by_price:"Prezzo alto - basso",
        option_order_by_price_desc:"Prezzo basso - alto",
        btn_scopri_di_piu:"Scopri di più",
        lbl_filter_hair:"Come sono i tuoi capelli?",
        lbl_filter_product:"Quale prodotto cerchi?",
        lbl_filter_color:"Qual è il tuo colore naturale?",
        btn_applica_filtri:"Applica filtri",
        lbl_no_products:"Non ci sono prodotti disponibili con la tua ricerca"
    }
});

export const stringsProductDetail = new LocalizedStrings({
    it: {
        lbl_ean: "Codice EAN:",
        btn_how_to_use:"COME USARLO",
        lbl_scroll:"Scrolla per scoprire di più",
        lbl_features:"Caratteristiche principali",
        lbl_effects:"Effetti",
        lbl_treatment:"Trattamento",
        lbl_title_ingredients:"Ingredienti",
        lbl_title_application:"Tempo di applicazione",
        lbl_subtitle_application_1:"Colorazione totale",
        lbl_subtitle_application_2:"Colorazione parziale",
        lbl_minutes:"Minuti di posa",
        lbl_lot_hair:"Molti capelli bianchi",
        lbl_few_hair:"Pochi capelli bianchi",
        lbl_other_hair:"Sul resto della capigliatura",
        lbl_title_suggested_products:"Utilizzalo con",
        lbl_subtitle_suggested_products:"",
        btn_scopri_di_piu:"Scopri di più"
    }
});

export const stringsCreaIlTuoLook = new LocalizedStrings({
    it: {
        lbl_tutorial: "Tutorial",
        lbl_title_avvertenze : "Avvertenze",
        lbl_content_avvertenze : "Utilizzare sempre un paio di guanti per evitare che le mani possano venire a contatto con la tinta per capelli. Se durante l’applicazione la pelle si dovesse macchiare, utilizzare un batuffolo di cotone imbevuto di acqua e sapone per rimuovere ogni segno di tinta per capelli.",
        btn_torna_su:"TORNA SU"
    }
});

export const stringsTrovaIlTuoColore = new LocalizedStrings({
    it: {
        btn_prosegui: "Prosegui",
        btn_risultato: "Scopri il risultato",
        lbl_ean:"Codice EAN:",
        lbl_results:"Ecco i tuoi risultati"
    }
});
