import * as utils from './utils'
import * as strings from './strings'

const PARAM_BYPASS_LANDING = "bypassLanding";

export const NAVIGATION_HOME = "/" ;
export const NAVIGATION_FAQ = getUrlWithParam(strings.page_names.page_faq);;
export const NAVIGATION_PRODUCTS = getUrlWithParam(strings.page_names.page_products) ;
export const NAVIGATION_CONTACT_US = getUrlWithParam(strings.page_names.page_contact_us);
export const NAVIGATION_PRODUCT_DETAIL = getUrlWithParam(strings.page_names.page_product_detail);
export const NAVIGATION_TROVA_IL_TUO_COLORE = getUrlWithParam(strings.page_names.page_find_your_color);
export const NAVIGATION_CREA_IL_TUO_LOOK = getUrlWithParam(strings.page_names.page_create_your_look);
export const NAVIGATION_LANDING = "/landing";


export const PARAM_PRODUCT_ID = "id";
export const SUBJECT_CONTACT_US = "Richiesta informazioni";
export const EMAIL_BODY_CONTACT_US = ""

export const IS_SITE_CLOSED = false;

export const COOKIE_POPUP = "monthly_product";
export const COOKIE_POSITION = "position";
export const COOKIE_SPLASH = "splash";


//api:
export const API_GET_MONTHLY_PRODUCT = "/api/v1/month-product/";
export const API_GET_PRODUCTS = "/api/v1/products/";
export const API_GET_METATAGS = "/api/v1/metatag/";
export const API_GET_FAQ = "/api/v1/faq/";
export const API_GET_TUTORIAL = "/api/v1/create_your_look/";
export const API_GET_QUIZ = "/api/v1/find_your_color/";
export const API_GET_NEXT_QUESTION_QUIZ = "/api/v1/find_your_color/";//post
export const API_GET_PRODUCT_DETAIL = "/api/v1/product_detail/";
export const API_GET_QUIZ_RESULT = "/api/v1/find_your_color/";
export const API_SEND_EMAIL = "/api/v1/contact_us/";
export const API_GET_PRODUCT_CATEGORIES = "/api/v1/categories/";
export const API_GET_HAIR_TYPE = "/api/v1/hair_types/";
export const API_GET_HAIR_COLOR = "/api/v1/hair_colors/";
export const API_GET_STORES = "/api/v1/stores/";//post

export const GOOGLE_API_KEY = "AIzaSyBgwaGOmWeW7aUhGxltzufa5k01QSkWV1g";
function getUrlWithParam(url){
    if(utils.getParam(PARAM_BYPASS_LANDING) && utils.getParam(PARAM_BYPASS_LANDING) == "true"){
        return utils.addParam(url, PARAM_BYPASS_LANDING,utils.getParam(PARAM_BYPASS_LANDING));
    }else{
        return url;
    }
}
