const axios = require('axios');

export default class Request{

    constructor(_endpoint, _method, _body) {
        this.host = process.env.API_URL;
        this.endpoint = this.host + _endpoint;
        this.method = _method;
        this.body = _body;

        function buildGetParams(_body){
            let params = "";
            if(_body){
                params = "?";
                for(let key in _body){
                    params += key + "=" + _body[key]
                }
            }
            return params;
        }

        this.executeRequest = async ()=>{
            const response = {};
            response.status = "OK";

            try {
                if(_method.toLowerCase() === "get"){
                    const params = buildGetParams(this.body);
                    response.result = await axios.get(this.endpoint + params);
                }
                else if(_method.toLowerCase() === "post"){
                    response.result = await axios.post(this.endpoint, this.body);
                }

                return response;
            } catch (error) {
                response.status = "KO";
                response.error = error;

                console.error(error);
            }

            return response;
        }

        this.executeRequestAsync = (cbSuccess,cbFail,cbFinally)=>{
            if(_method.toLowerCase() === "get"){
                const params = buildGetParams(this.body);
                axios.get(this.endpoint + params).then((result)=>{
                    if(cbSuccess) cbSuccess(result)
                }).catch((error)=> {
                    if(cbFail) cbFail(error)
                  })
                .then(()=> {
                    if(cbFinally) cbFinally()
                });
            } else if(_method.toLowerCase() === "post"){
                axios.post(this.endpoint,this.body).then((result)=>{
                    if(cbSuccess) cbSuccess(result)
                }).catch((error)=> {
                    if(cbFail) cbFail(error)
                  })
                .then(()=> {
                    if(cbFinally) cbFinally()
                });
            }
        }
    }
}
