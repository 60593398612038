import React from 'react';
import css from './Logo.css'
import * as strings from '../../utils/strings'
import * as constants from '../../utils/constants'
import logo from '../../images/logo-biokeratin.svg'
import logo_light from '../../images/logo-biokeratin-light.svg'
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Logo = (props) =>(
    <AniLink duration={0.6} fade to={props.landing?null:constants.NAVIGATION_HOME} className="wrapperLogo">
        <img src={props.light?logo_light:logo}/>
    </AniLink>
    
);

export default Logo;